<template>
  <div class="right">
    <p @click="showCityInfo" class="dingwei">
      <i class="el-icon-aim" style="margin-right: 5px; color: #69e5ff"></i>
      <span>回到当前定位</span>
    </p>
    <div class="device">
      <p class="title">产品信息注册量</p>
      <div class="cylinder">
        <div class="main">
          <img class="icon" src="@/assets/map/enterprise.png" />
          <div class="num">
            <p style="font-size: 16px; color: #b3b6bd; text-align: center">企业注册数</p>
            <div>
              <!-- <p class="my-number">5</p> -->
              <!-- <p class="my-number">{{ productData.Enterprise }}</p> -->
              <p class="my-number">{{ productData.enterpriseCount }}</p>
              
              <img src="@/assets/map/line.png" />
            </div>
          </div>
        </div>
        <div class="main">
          <img class="icon" src="@/assets/map/products.png" />
          <div class="num">
            <p style="font-size: 16px; color: #b3b6bd; text-align: center">产品数</p>
            <div>
              <!-- <p class="my-number">750983</p> -->
              <!-- <p class="my-number">{{ productData.Product }}</p> -->
              <p class="my-number">{{ productData.productCount }}</p>
              
              <img src="@/assets/map/line.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="device" style="margin-top: 30px">
      <p class="title">解析量</p>
      <div>
        <div>
          <ul class="all">
            <li>
              <span>
                <i style="background-color: #5470c6"></i>
                <span>标识注册数</span>
              </span>
              <!-- <p>总数(750983)</p> -->
              <p>总数({{ idNumberData.total }})</p>
            </li>
            <li>
              <span>
                <i style="background-color: #91cc75"></i>
                <span>标识解析数</span>
              </span>
              <!-- <p>总数(2252949)</p> -->
              <p>总数({{ idQueryData.total }})</p>
            </li>
          </ul>
        </div>
        <div id="polyline"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {idNumber, idQuery, countProduct, dataScreenSummary} from '../api';
export default {
  data() {
    return {
      productData: {},
      idNumberData: {},
      idQueryData: {}
    };
  },
  created() {
    this.getCountProduct();
    Promise.all([idNumber(), idQuery()]).then(res => {
      if (res[0].errno === 0) {
        this.idNumberData = res[0].data;
      }
      if (res[1].errno === 0) {
        this.idQueryData = res[1].data;
      }
      // 绘制折线图
      this.getPolyline();
    });
  },
  methods: {
    showCityInfo() {
      this.$emit('toPosition');
    },
    async getCountProduct() {
      // let res = await countProduct();
      let res = await dataScreenSummary();
      
      // console.log( res, '企业注册数量' )
      this.productData = res.data;
      
    },
    getPolyline() {
      var myChart = this.$echarts.init(document.getElementById('polyline'));
      window.onresize = function () {
        myChart.resize();
      };
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.idNumberData.List.map(item => item.mon)
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          textStyle: {
            color: '#4ed8fe'
          },
          data: ['标识数', '解析数']
        },
        textStyle: {
          color: '#fff'
        },
        grid: {
          width: '85%',
          top: 30,
          bottom: 30,
          left: 40
        },
        series: [
          {
            data: this.idNumberData.List.map(item => item.num),
            type: 'line',
            name: '标识数',
            smooth: true
          },
          {
            data: this.idQueryData.List.map(item => item.num),
            type: 'line',
            name: '解析数',
            smooth: true
          }
        ]
      });
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
#polyline {
  width: 100%;
  height: 210px;
}
#cylinder {
  width: 100%;
  height: 210px;
}
.dingwei {
  cursor: pointer;
  padding: 5px 15px;
  background-color: #1c5d94;
  border: 1px solid #69e5ff;
  border-radius: 50px;
  position: absolute;
  top: 90px;
  right: 30px;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 26%;
  padding: 145px 30px 200px 0;
}
.device {
  padding: 25px 25px 20px 30px;
  width: 470px;
  height: 360px;
  background: url('../../../assets/map/parse.png') no-repeat;
  background-size: 100% 100%;
}
.title {
  text-align: right;
  padding-right: 25px;
  font-size: 20px;
  color: #fff;
}
.number {
  text-align: center;
  margin: 28px 0 0 0;
  font-size: 20px;
  color: #89eaff;
}
.all {
  display: flex;
  justify-content: space-around;
  width: 410px;
  margin-top: 30px;
  li {
    text-align: center;
  }
  i {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #fac858;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 5px;
  }
  span {
    font-size: 15px;
    color: #fff;
  }
  p {
    margin-top: 14px;
    font-size: 15px;
    color: #89eaff;
  }
}
.cylinder {
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.main {
  display: flex;
  width: 100%;
  justify-content: center;
  .icon {
    width: 113px;
    height: 88px;
    margin-right: 15px;
  }
  .num {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .my-number {
    color: #4ed8fe;
    text-align: center;
    font-family: Unidreamled;
    margin-bottom: -20px;
    font-size: 50px;
  }
}
</style>
