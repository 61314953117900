<template>
  <div class="left">
    <!-- 设备情况 -->
    <div class="device">
      <p class="title">设备信息</p>
      <div>
        <p class="all">
          设备总数量<span style="color: #fff">({{ equipmentData.total }}台)</span>
        </p>
      </div>
      <div class="progress">
        <el-progress type="circle" color="#12d0f1" :stroke-width="10" :percentage="getMath(equipmentData.online)"></el-progress>
        <el-progress type="circle" color="#fca81c" :stroke-width="10" :percentage="getMath(equipmentData.offline)"></el-progress>
        <el-progress type="circle" color="#68ecd5" :stroke-width="10" :percentage="getMath(equipmentData.move)"></el-progress>
      </div>
      <div class="left-equipment">
        <div> 
          <p>{{ equipmentData.online }}</p>
          <span>设备离线</span>
        </div>
        <div>
          <p>{{ equipmentData.offline }}</p>
          <span>设备在线</span>
        </div>
        <div>
          <p>{{ equipmentData.move }}</p>
          <span>设备移动中</span>
        </div>
      </div>
    </div>
    <!-- 项目情况 -->
    <div class="device my-project">
      <p class="title">最新项目情况</p>
      <div class="allc">
        <p class="all-number">
          项目总数<span style="color: #fff">({{ allLength }})</span>
        </p>
        <span class="mouseStyle more" @click="$refs.dialogList.dialogVisible = true">更多项目>></span>
      </div>
      <ul class="devLi">
        <li v-for="(item, index) in projectList" :key="index">
          <p class="textOverflow">{{ item.projName }}</p>
          <span class="mouseStyle" @click="tomyDetail(item)">查看详情</span>
        </li>
      </ul>
    </div>
    <!-- 合同详情弹框组件 -->
    <dialog-detail ref="dialogDetail" @toEquipment="toEquipment" @toTraceabilityDetail="toTraceabilityDetail" @toDeliveriesDetail="toDeliveriesDetail" />
    <!-- 设备详情弹框组件 -->
    <dialog-equipment ref="dialogEquipment" @backDetail="backDetail" />
    <!-- 设备列表弹框组件 -->
    <dialog-list ref="dialogList" @toDetail="toDetail" />
    
    <!-- 设备溯源弹框组件 -->
    <dialog-Traceability ref="dialogTraceability" @backDetail="backDetail" />

    <!-- 物资交付弹框组件 -->
    <dialog-Deliveries ref="dialogDeliveries" @backDetail="backDetail" />

    <!--  -->
    <!-- <dialog-device-Information ref="dialog-device-Information" /> -->
  </div>
</template>
<script>
import {messageAll, cloudList} from '../api';
export default {
  data() {
    return {
      equipmentData: {},
      allLength: '',
      projectList: []
    };
  },
  created() {
    // 获取项目列表
    this.getCloudList();
    // 获取设备在线数量
    this.getMessageAll();
  },
  components: {
    dialogDetail: () => import('@/modules/DeviceMap/components/dialog-detail.vue'),
    dialogTraceability: () => import('@/modules/DeviceMap/components/dialog-traceability.vue'),
    dialogDeliveries: () => import('@/modules/DeviceMap/components/dialog-deliveries.vue'),
    dialogList: () => import('@/modules/DeviceMap/components/dialog-list.vue'),
    dialogEquipment: () => import('@/modules/DeviceMap/components/dialog-equipment.vue'),
    dialogDeviceInformation: () => import('@/modules/DeviceMap/components/dialog-device-Information.vue')
  },
  methods: {
    getMath(data) {
      if (!this.equipmentData.total) return 0;
      return (data / this.equipmentData.total).toFixed(2) * 100;
    },
    async getCloudList() {
      let res = await cloudList();
      if (res.errno === 0) {
        this.projectList = res.data.list.slice(0, 5);
        this.allLength = res.data.list.length;
      }
    },
    async getMessageAll() {
      let res = await messageAll();
      if (res.errno === 0) {
        this.equipmentData = res.data;
        // console.log(  res.data, '设备数据-测试' )
        // 2024-6-14  需求要求写死数据
        this.equipmentData.online = 16
        this.equipmentData.offline = 4
        this.equipmentData.move = 0
        this.equipmentData.total = 20
      }
    },
    tomyDetail(item) {
      this.$refs.dialogDetail.dialogVisible = true;
      this.$refs.dialogDetail.id = item.id;
    },
    toEquipment(data) {
      this.$refs.dialogDetail.dialogVisible = false;
      this.$refs.dialogEquipment.dialogVisible = true;
      this.$refs.dialogEquipment.ContractId = data.id;
    },
    backDetail() {
      // 设备详情弹框组件隐藏
      this.$refs.dialogEquipment.dialogVisible = false;
      // 设备溯源弹框组件隐藏
      this.$refs.dialogTraceability.dialogVisible = false;
      this.$refs.dialogDeliveries.dialogVisible = false;
      this.$refs.dialogDetail.dialogVisible = true;
    },
    toDetail(data) {
      this.$refs.dialogList.dialogVisible = false;
      this.$refs.dialogDetail.dialogVisible = true;
      this.$refs.dialogDetail.id = data.id;
    },
    toTraceabilityDetail(data) {
      this.$refs.dialogDetail.dialogVisible = false;
      this.$refs.dialogDeliveries.dialogVisible = false;
      this.$refs.dialogTraceability.dialogVisible = true;
      this.$refs.dialogTraceability.contractOn = data.id;
    },

    toDeliveriesDetail(id){
      this.$refs.dialogDetail.dialogVisible = false;
      this.$refs.dialogTraceability.dialogVisible = false;
      this.$refs.dialogDeliveries.dialogVisible = true;
      this.$refs.dialogDeliveries.contractOn = id;
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.progress ::v-deep .el-progress-circle {
  width: 115px !important;
  height: 115px !important;
}
.left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 26%;
  padding: 145px 0 200px 30px;
}
.device {
  padding: 25px 25px 20px 30px;
  width: 470px;
  height: 360px;
  background: url('../../../assets/map/device.png') no-repeat;
  background-size: 100% 100%;
}
.title {
  padding-left: 20px;
  font-size: 20px;
  color: #fff;
}
.more {
  font-size: 16px;
  color: #fff;
}
.all-number {
  font-size: 18px;
  color: #89eaff;
}
.all {
  padding: 0 10px;
  margin: 40px 0 30px 0;
  font-size: 20px;
  color: #89eaff;
}
.number {
  text-align: center;
  margin: 28px 0 0 0;
  font-size: 20px;
  color: #89eaff;
}

.num {
  font-family: Unidreamled;
  padding: 0 10px;
  display: flex;
  justify-content: space-around;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 55px;
    font-size: 36px;
    color: #89eaff;
    background-color: #0c2a63;
    border: 4px solid #0552a8;
  }
}
.progress {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}
.left-equipment {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  p {
    text-align: center;
    color: #fff;
    font-family: Unidreamled;
    font-size: 40px;
  }
  span {
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
  }
}
.allc {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-project {
  margin-top: 30px;
  padding: 25px 40px 20px;
}
.devLi {
  margin-top: 18px;
  li {
    height: 40px;
    background-color: rgba(24, 59, 93, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid RGB(21, 59, 92);
    p {
      font-size: 15px;
      color: #fff;
      width: 310px;
    }
    span {
      font-size: 15px;
      color: #fff;
      text-decoration: underline;
    }
  }
}
</style>
