var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "left" },
    [
      _c("div", { staticClass: "device" }, [
        _c("p", { staticClass: "title" }, [_vm._v("设备信息")]),
        _c("div", [
          _c("p", { staticClass: "all" }, [
            _vm._v(" 设备总数量"),
            _c("span", { staticStyle: { color: "#fff" } }, [
              _vm._v("(" + _vm._s(_vm.equipmentData.total) + "台)"),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "progress" },
          [
            _c("el-progress", {
              attrs: {
                type: "circle",
                color: "#12d0f1",
                "stroke-width": 10,
                percentage: _vm.getMath(_vm.equipmentData.online),
              },
            }),
            _c("el-progress", {
              attrs: {
                type: "circle",
                color: "#fca81c",
                "stroke-width": 10,
                percentage: _vm.getMath(_vm.equipmentData.offline),
              },
            }),
            _c("el-progress", {
              attrs: {
                type: "circle",
                color: "#68ecd5",
                "stroke-width": 10,
                percentage: _vm.getMath(_vm.equipmentData.move),
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "left-equipment" }, [
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.equipmentData.online))]),
            _c("span", [_vm._v("设备离线")]),
          ]),
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.equipmentData.offline))]),
            _c("span", [_vm._v("设备在线")]),
          ]),
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.equipmentData.move))]),
            _c("span", [_vm._v("设备移动中")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "device my-project" }, [
        _c("p", { staticClass: "title" }, [_vm._v("最新项目情况")]),
        _c("div", { staticClass: "allc" }, [
          _c("p", { staticClass: "all-number" }, [
            _vm._v(" 项目总数"),
            _c("span", { staticStyle: { color: "#fff" } }, [
              _vm._v("(" + _vm._s(_vm.allLength) + ")"),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "mouseStyle more",
              on: {
                click: function ($event) {
                  _vm.$refs.dialogList.dialogVisible = true
                },
              },
            },
            [_vm._v("更多项目>>")]
          ),
        ]),
        _c(
          "ul",
          { staticClass: "devLi" },
          _vm._l(_vm.projectList, function (item, index) {
            return _c("li", { key: index }, [
              _c("p", { staticClass: "textOverflow" }, [
                _vm._v(_vm._s(item.projName)),
              ]),
              _c(
                "span",
                {
                  staticClass: "mouseStyle",
                  on: {
                    click: function ($event) {
                      return _vm.tomyDetail(item)
                    },
                  },
                },
                [_vm._v("查看详情")]
              ),
            ])
          }),
          0
        ),
      ]),
      _c("dialog-detail", {
        ref: "dialogDetail",
        on: {
          toEquipment: _vm.toEquipment,
          toTraceabilityDetail: _vm.toTraceabilityDetail,
          toDeliveriesDetail: _vm.toDeliveriesDetail,
        },
      }),
      _c("dialog-equipment", {
        ref: "dialogEquipment",
        on: { backDetail: _vm.backDetail },
      }),
      _c("dialog-list", { ref: "dialogList", on: { toDetail: _vm.toDetail } }),
      _c("dialog-Traceability", {
        ref: "dialogTraceability",
        on: { backDetail: _vm.backDetail },
      }),
      _c("dialog-Deliveries", {
        ref: "dialogDeliveries",
        on: { backDetail: _vm.backDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }