var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c("div", { staticClass: "dialog-detail" }, [
        _c("div", { staticClass: "dialog-detail-main" }, [
          _c("section", [
            _c("div", [
              _c("img", {
                staticClass: "title",
                attrs: { src: require("@/assets/map/msg-title.png") },
              }),
              _c("i", {
                staticClass: "el-icon-circle-close icon",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "dialog-detail-company" }, [
              _c("img", {
                attrs: { src: require("@/assets/map/company.png") },
              }),
              _c("div", { staticClass: "company-msg" }, [
                _c("p", { staticStyle: { color: "#fff" } }, [
                  _vm._v("地址：" + _vm._s(_vm.detailData.formattedAddress)),
                ]),
                _c("p", { staticStyle: { color: "#fff" } }, [
                  _vm._v(
                    "定位类型：" +
                      _vm._s(_vm._f("getType")(_vm.detailData.locationType))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "equipment" }, [
                _c("p", [
                  _vm._v("设备名称：" + _vm._s(_vm.detailData.trackerName)),
                ]),
                _c("p", [_vm._v("设备编号：" + _vm._s(_vm.detailData.imei))]),
                _c("p", [
                  _vm._v("定位时间：" + _vm._s(_vm.detailData.locationTime)),
                ]),
              ]),
            ]),
          ]),
          _c("section", { staticClass: "dialog-detail-electricity" }, [
            _c("div", { staticClass: "img" }, [
              _c("p", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.detailData.electricity) + "%"),
              ]),
              _c("p", { staticClass: "my-title" }, [_vm._v("设备电量")]),
            ]),
            _c("div", { staticClass: "img" }, [
              _c("div", [
                _c("p", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.detailData.speed)),
                  _c("span", { staticClass: "speed" }, [_vm._v("km/h")]),
                ]),
              ]),
              _c("p", { staticClass: "my-title" }, [_vm._v("设备速度")]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }