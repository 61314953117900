var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right" }, [
    _c("p", { staticClass: "dingwei", on: { click: _vm.showCityInfo } }, [
      _c("i", {
        staticClass: "el-icon-aim",
        staticStyle: { "margin-right": "5px", color: "#69e5ff" },
      }),
      _c("span", [_vm._v("回到当前定位")]),
    ]),
    _c("div", { staticClass: "device" }, [
      _c("p", { staticClass: "title" }, [_vm._v("产品信息注册量")]),
      _c("div", { staticClass: "cylinder" }, [
        _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/map/enterprise.png") },
          }),
          _c("div", { staticClass: "num" }, [
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "16px",
                  color: "#b3b6bd",
                  "text-align": "center",
                },
              },
              [_vm._v("企业注册数")]
            ),
            _c("div", [
              _c("p", { staticClass: "my-number" }, [
                _vm._v(_vm._s(_vm.productData.enterpriseCount)),
              ]),
              _c("img", { attrs: { src: require("@/assets/map/line.png") } }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/map/products.png") },
          }),
          _c("div", { staticClass: "num" }, [
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "16px",
                  color: "#b3b6bd",
                  "text-align": "center",
                },
              },
              [_vm._v("产品数")]
            ),
            _c("div", [
              _c("p", { staticClass: "my-number" }, [
                _vm._v(_vm._s(_vm.productData.productCount)),
              ]),
              _c("img", { attrs: { src: require("@/assets/map/line.png") } }),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "device", staticStyle: { "margin-top": "30px" } },
      [
        _c("p", { staticClass: "title" }, [_vm._v("解析量")]),
        _c("div", [
          _c("div", [
            _c("ul", { staticClass: "all" }, [
              _c("li", [
                _vm._m(0),
                _c("p", [
                  _vm._v("总数(" + _vm._s(_vm.idNumberData.total) + ")"),
                ]),
              ]),
              _c("li", [
                _vm._m(1),
                _c("p", [
                  _vm._v("总数(" + _vm._s(_vm.idQueryData.total) + ")"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { attrs: { id: "polyline" } }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticStyle: { "background-color": "#5470c6" } }),
      _c("span", [_vm._v("标识注册数")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticStyle: { "background-color": "#91cc75" } }),
      _c("span", [_vm._v("标识解析数")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }