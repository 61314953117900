<template>
  <div class="wrap">
    <div id="container">
      <div class="title">
        <img src="@/assets/map/title.png" alt="" />
      </div>
    </div>
    <Home-left />
    <HomeRight @toPosition="showCityInfo" />
    <!-- 地图弹窗 -->
    <dialog-device-Information ref="dialogDeviceInformation" />
  </div>
</template>

<script>
import CustomDialog from './components/CustomDialog.vue';
import HomeLeft from './components/left';
import HomeRight from './components/right';
import HomeBottom from './components/bottom';
import dialogDeviceInformation from '@/modules/DeviceMap/components/dialog-device-Information.vue';

import AMapLoader from '@amap/amap-jsapi-loader';

import {trackers} from './api';

export default {
  name: 'DeviceMap',

  components: {
    dialogDeviceInformation,
    CustomDialog,
    HomeLeft,
    HomeRight,
    HomeBottom
  },

  data() {
    return {
      map: '',
      markers: [
        {
          clientName: '佛山市南海三湘铝业材料有限公司',
          icon: require('../../assets/map/logo1.jpg'),
          offAddr: '佛山市南海三湘铝业材料有限公司'
        }
      ],
      dialogShow: false,
      Datas: [],
      allDevice: {}, // 所有的设备点
      nowDevice: [], // 操作完后的设备点
      addDevice: [], // 增加显示设备点
      delDevuce: [], // 去掉显示设备点
      ceshi: [],
      userId: ''
    };
  },
  watch: {
    geocodeData: function (param) {
      let myArr = param;
      this.map.setZoomAndCenter(12, myArr);
    }
  },
  computed: {
    geocodeData() {
      return this.$store.state.geocodeData;
    }
  },
  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: 'bf61e47ee618e7e5c705055c41a39441'
    };
    this.initMap(); // 初始化背景地图
  },
  methods: {
    //从后管预览跳转到设备合同获取传递过来的id
    urlmethod() {
      let urlParam = location.href;
      if (urlParam.indexOf('id') !== -1) {
        let param = urlParam.split('=');
        this.userId = Number(param[1].replace('ab', '').replace('gf', '')) / 100;
      }
    },

    // 初始化背景地图
    initMap() {
      const _this = this;
      AMapLoader.load({
        key: 'ac0af4292d01f5c5861592a599ab53c8', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['ElasticMarker', 'AMap.Geocoder', 'AMap.Geolocation'] // 需要使用的插件列表
      })
        .then(AMap => {
          _this.map = new AMap.Map('container', {
            resizeEnable: true, //是否监控地图容器尺寸变化
            // mapStyle: 'amap://styles/9aa188c64d4b744d1228b2d65b188a5f',
            // mapStyle: 'amap://styles/9922d513e334fb6149d242211d3d25ab',
            zoom: 5, //级别
            zooms: [5, 20]
            // center: [114.24278, 28.83082] //中心点坐标
            // center: [11, 28] //中心点坐标
          }).on('complete', function () {
            _this.getClientInfos();
          });
          _this.map.add(new AMap.TileLayer.Satellite());
          _this.showCityInfo();
          //   _this.map.plugin('AMap.Geolocation', function () {
          //     let geolocation = new AMap.Geolocation({
          //       // 设置定位超时时间，默认：无穷大
          //       timeout: 10000,
          //       // 定位按钮的停靠位置的偏移量
          //       offset: [10, 20],
          //       //  定位按钮的排放位置,  RB表示右下
          //       position: 'RT'
          //     });
          //     _this.map.addControl(geolocation);
          //   });
        })
        .catch(e => {
          console.error(e); //加载错误提示
        });
    },
    //获取用户所在城市信息
    showCityInfo() {
      var geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, //是否使用高精度定位，默认:true
        timeout: 10000, //超过10秒后停止定位，默认：5s
        showButton: false,
        showCircle: false,
        markerOptions: new AMap.Marker()
      });
      this.map.addControl(geolocation);
      geolocation.getCurrentPosition();
      setTimeout(() => {
        this.map.setZoom(12);
      }, 100);
    },
    // 获取企业信息
    async getClientInfos() {
      let res = await trackers();
      let myObj = [];
      if (res.errno === 0) {
        res.data.forEach(e => {
          myObj.push({
            clientInfoId: e.trackerid,
            clientName: e.dealer,
            position: [e.la.longitude, e.la.latitude]
          });
        });
        this.markers = myObj;
        this.addMarker();
        this.map.setZoom(12);
      }
    },

    // 添加标记点
    addMarker() {
      const _this = this;
      //   this.map.clearMap(); // 删除所有覆盖物
      this.markers.forEach(function (marker) {
        let markerinit = new AMap.Marker({
          //添加点标记
          map: _this.map,
          position: marker.position,
          anchor: 'center',
          content: `
                    <div class='markers-box'>
                    </div>
                  `,
          trackerid: marker.trackerid,
          offset: new AMap.Pixel(52, -40), //设置偏移量
          topWhenClick: true //鼠标点击时marker是否置顶
        });

        _this.allDevice[marker.imei] = markerinit;

        //实例化信息窗体
        // let infoWindow = new AMap.InfoWindow({
        //   isCustom: true, //使用自定义窗体
        //   content: _this.createInfoWindow(marker),
        //   offset: new AMap.Pixel(-150, -50),
        //   closeWhenClickMap: true
        // });

        //鼠标点击marker弹出自定义的信息窗体
        markerinit.on('click', function (ev) {
          _this.$refs.dialogDeviceInformation.dialogVisible = true;
          _this.$refs.dialogDeviceInformation.trackerid = marker.clientInfoId;
          // 放大点标记
          //   const _self = this;
          //   _this.markers.forEach(function (item) {
          //     let old_content = `
          //             <div class='markers-box '>
          //             </div>
          //           `;
          //     let new_content = `
          //             <div class='markers-box markers2'>
          //             </div>
          //           `;
          //   });
          //   // 弹出自定义的信息窗体
          //   infoWindow.open(_this.map, _self.getPosition());
          // 显示数据大屏
          // _this.dialogShow = true;
        });
        // marker.marker = markerinit;
      });
    },

    //构建自定义信息窗体
    createInfoWindow(title) {
      var box = document.createElement('div');
      box.className = 'custom-info-card';

      // 定义内容
      var info = document.createElement('div');
      info.className = 'info';
      info.innerHTML = `
                    <div class='device-dialog'>
                         <div class='device-dialog-title'>项目详情</div>
                         <div class='device-dialog-titleImg'></div>
                    </div>
                    <div class='device-dialog-body'>

                      <div class='dialog-body-row' >
                          <div  class="body-row-1" >所属企业：</div>
                          <div  class="body-row-2" >${title.clientName}</div>
                          <div  class="body-row-3" >设备编号：</div>
                          <div  class="body-row-4" >${title.imei}</div>
                      </div>

                        <div class='dialog-body-row' >
                          <div  class="body-row-1">详细地址：</div>
                          <div  class="body-row-2" >${title.formattedAddress}</div>
                          <div  class="body-row-3">设备名称：</div>
                          <div  class="body-row-4" >${title.trackerName}</div>
                      </div>

                      <div class='dialog-body-row' >
                          <div  class="body-row-1">定位类型：</div>
                          <div  class="body-row-2" >${title.coordinateType == 'E_N' ? '经度_纬度' : ''}</div>
                          <div  class="body-row-3">电量：</div>
                          <div  class="body-row-4" >${title.electricity}%</div>
                      </div>

                      <div class='dialog-body-row' >
                          <div  class="body-row-1">定位时间：</div>
                          <div  class="body-row-2" >${title.locationTime} </div>
                          <div  class="body-row-3">速度：</div>
                          <div  class="body-row-4" >${title.speed}km/h</div>
                      </div>

                    </div>
                    `;

      var tips = document.createElement('div');
      var closeImg = document.createElement('img');
      closeImg.src = 'https://storage.linkeasier.com/files/close-img.png';
      closeImg.onclick = () => {
        this.closeInfoWindow();
      };
      closeImg.style.position = 'absolute';
      closeImg.style.top = '13px';
      closeImg.style.right = '15px';
      closeImg.style.cursor = 'pointer';

      tips.className = 'info-tips';

      info.appendChild(tips);
      box.appendChild(info);
      box.appendChild(closeImg);
      return box;
    },

    //关闭信息窗体
    closeInfoWindow() {
      this.map.clearInfoWindow();
    },

    // 获取小地图触发区域
    getCoordinate(event) {
      console.log(event);
      if (event.type == 'province') {
        this.map.setCity(event.name, () => {
          this.map.setZoom(9);
        });
      } else if (event.type == 'city') {
        this.map.setCity(event.name, () => {
          this.map.setZoom(12);
        });
      } else {
        this.map.setCity(event.name, () => {
          this.map.setZoom(14);
        });
      }
    },

    // 省市区缩放按钮
    setMapZoom(type) {
      if (type === 'province') {
        this.map.setZoom(7);
      } else if (type === 'city') {
        this.map.setZoom(12);
      } else {
        this.map.setZoom(17);
      }
    },

    tableListShow(e) {
      this.dialogShow = e;
    },

    getdeviceId(id) {
      this.map.clearMap();
      var eviceId = [];
      id.forEach(e => {
        eviceId.push(this.allDevice[e]);
      });
      this.map.add(eviceId);
    }
  }
};
</script>

<style lang="less" scoped>
.mask-layer-top {
  width: 100%;
  height: 16%;
  background-color: #091342;
  opacity: 0.3;
}
.mask-layer-left {
  position: absolute;
  top: 16%;
  left: 0;
  width: 26%;
  height: 60%;
  background-color: #091342;
  opacity: 0.3;
}
.mask-layer-right {
  position: absolute;
  top: 16%;
  right: 0;
  width: 26%;
  height: 60%;
  background-color: #091342;
  opacity: 0.3;
}
.mask-layer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 24%;
  width: 100%;
  background-color: #091342;
  opacity: 0.3;
}
/deep/ .my-icon {
  background: url('../../assets/locale.png');
}
/deep/ .markers-box {
  width: 105px;
  height: 198px;
  background: url('../../assets/map/poi-marker-1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  transition: all 0.4s ease;
  transform: scale(0.6);
}

/deep/ .markers-box.markers2 {
  transform: scale(1);
}

/deep/ .markers-box .box {
  position: absolute;
  left: 13px;
  top: 2px;
  border-radius: 50%;
  width: 79px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  padding-top: 5px;
  /* background: #096; */
}

/deep/ .markers-box .box img {
  width: 46px;
  height: 46px;
}

/deep/ .custom-info-card {
  position: relative;
  border: 2px solid #0040b2;
}

/deep/ .custom-info-card .info-left {
  position: absolute;
  left: -20px;
  top: 25px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-color: transparent #00ebff transparent transparent;
  border-width: 12px 20px 12px 0;
}

/deep/ .custom-info-card .info {
  position: relative;
  width: 610px;
  // line-height: 43px;
  border: 1px solid #0247ed;
  background: rgba(0, 17, 56, 0.9);
  color: #fff;
  font-size: 20px;
  box-shadow: 0px 0px 20px rgba(0, 85, 255, 0.6) inset;
  overflow: hidden;
  text-overflow: ellipsis;

  .tips {
    position: absolute;
    left: 0;
    top: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-color: #00ebff transparent transparent #00ebff;
    border-width: 0px 0px 13px 13px;
  }
}
// 项目详情
/deep/.device-dialog {
  background: #0040b2;

  .device-dialog-title {
    line-height: 43px;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }
}

/deep/.device-dialog-body {
  padding: 20px 10px 0px;
  line-height: 1;

  .dialog-body-row {
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
    &:nth-child(4) {
      .body-row-2 {
        width: 200px;
        padding-right: 5px;
      }
    }
    .body-row-1 {
      flex: none;
      width: 95px;
      text-align: right;
      white-space: nowrap;
      color: #00ebff;
      margin-bottom: 20px;
    }

    .body-row-2 {
      flex: none;
      text-align: left;
      width: 200px;
      word-wrap: break-word;
      word-break: break-all;
      padding-right: 25px;
      box-sizing: border-box;
      margin-bottom: 20px;
      line-height: 20px;
    }

    .body-row-3 {
      flex: none;
      width: 95px;
      text-align: right;
      white-space: nowrap;
      color: #00ebff;
      margin-bottom: 20px;
    }

    .body-row-4 {
      flex: none;
      text-align: left;
      width: 190px;
      // width:40%;
      word-wrap: break-word;
      word-break: break-all;
      margin-bottom: 20px;
      line-height: 20px;
    }
  }
}

.wrap {
  position: relative;
  width: 100%;
}

#container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.title {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
}

.title img {
  width: 100%;
}

.border {
  height: 30px;
  width: 30px;
  position: absolute;
  border: 2px solid rgba(0, 235, 255, 0.5);
}

.bottom-main:hover .border {
  border: 4px solid #00ebff;
  width: 20px;
  height: 20px;
}

.map-control {
  position: fixed;
  top: 55px;
  right: 900px;
  display: flex;
}

.region-box {
  margin-left: 20px;
  margin-top: 25px;
}

.region-item {
  width: 80px;
  height: 44px;
  color: #00f7ff;
  background: rgba(0, 17, 56, 0.8);
  border: 1px solid rgba(1, 88, 214, 1);
  border-radius: 10px;
  font-size: 22px;
  text-align: center;
  line-height: 42px;
  margin-bottom: 50px;
  cursor: pointer;
  box-sizing: border-box;
}

.region-item:hover {
  background: rgb(0, 235, 255);
  color: rgb(0, 17, 56);
}

.home-right {
  position: absolute;
  right: 0;
  top: 0;
}

.visual-box {
  position: absolute;
  /* position: fixed; */
  bottom: 0;
  left: 50%;
  margin-left: -360px;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
}

.visual-item {
  align-items: center;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  margin-right: 20px;
}

.visual-item .circle {
  width: 40px;
  height: 20px;
  border: 10px solid;
  border-radius: 4px;
  box-sizing: border-box;
}

.visual-item .circle-title {
  width: 40px;
  text-align: center;
}

.visual-item .desc p {
  color: #62c0fd;
}
</style>
