<template>
  <div class="dialog-detail" v-if="dialogVisible">
    <div class="dialog-detail-main">
      <section>
        <div>
          <img class="title" src="@/assets/map/msg-title.png" />
          <i class="el-icon-circle-close icon" @click="dialogVisible = false"></i>
        </div>
        <div class="dialog-detail-company">
          <img src="@/assets/map/company.png" />
          <div class="company-msg">
            <p style="color: #fff">地址：{{ detailData.formattedAddress }}</p>
            <p style="color: #fff">定位类型：{{ detailData.locationType | getType }}</p>
          </div>
          <div class="line"></div>
          <div class="equipment">
            <p>设备名称：{{ detailData.trackerName }}</p>
            <p>设备编号：{{ detailData.imei }}</p>
            <p>定位时间：{{ detailData.locationTime }}</p>
          </div>
        </div>
      </section>
      <section class="dialog-detail-electricity">
        <div class="img">
          <p class="text">{{ detailData.electricity }}%</p>
          <p class="my-title">设备电量</p>
        </div>
        <div class="img">
          <div>
            <p class="text">{{ detailData.speed }}<span class="speed">km/h</span></p>
          </div>
          <p class="my-title">设备速度</p>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import {getTrackerById} from '../api';
export default {
  data() {
    return {
      trackerid: '',
      detailData: {},
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.getdetail();
      }
    }
  },
  filters: {
    getType(val) {
      switch (val) {
        case 'GSM':
          return '基站定位';
        case 'WIFI':
          return '网络定位';
        case 'GPSBD':
          return '北斗定位';
        case 'GPS':
          return '北斗定位';
      }
    }
  },
  methods: {
    async getdetail() {
      let res = await getTrackerById({trackerId: this.trackerid});
      if (res.errno === 0) {
        this.detailData = res.data;
      }
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.dialog-detail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding-top: calc(50%);
  background: rgba(1, 3, 9, 0.7);
  font-size: 16px;
  &-main {
    padding: 0 40px 0 55px;
    position: absolute;
    top: calc(50% - 280px);
    left: calc(50% - 520px);
    background: url('../../../assets/map/msg.png') no-repeat center/100%;
    z-index: 98;
    width: 1040px;
    height: 560px;
    .title {
      display: block;
      width: 245px;
      height: 59px;
      margin: -18px auto 0;
    }
    .icon {
      font-size: 36px;
      color: #34defc;
      position: absolute;
      top: -18px;
      right: 0;
    }
  }
  &-company {
    display: flex;
    align-items: center;
    padding: 40px 0 87px 55px;
    img {
      width: 150px;
      height: 112px;
      margin-right: 17px;
    }
    .font {
      font-size: 20px;
      color: #06d5f5;
    }
    .company-msg {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 112px;
      width: 295px;
    }
    .line {
      width: 2px;
      height: 75px;
      border: 1px solid #fff;
      margin: 0 50px 0 60px;
    }
    .equipment {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 112px;
      width: 300px;
      color: #fff;
    }
  }
  &-electricity {
    display: flex;
    justify-content: center;
    .img {
      padding-top: 37px;
      text-align: center;
      width: 239px;
      height: 185px;
      background: url('../../../assets/map/equipment.png') no-repeat center/100%;
    }
    .img:nth-child(1) {
      margin-right: 80px;
    }
    .text {
      text-align: center;
      color: #06d5f5;
      font-family: Unidreamled;
      font-size: 50px;
      margin-bottom: 20px;
    }
    .speed {
      font-family: none;
      font-size: 40px;
    }
    .my-title {
      margin-top: -30px;
      color: #88b1d0;
      font-size: 20px;
    }
  }
}
</style>
