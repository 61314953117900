import server from '@/api';

// 获取标识注册数
export function idNumber(params) {
  return server.equipment.idNumber(params);
}

// 获取标识解析数
export function idQuery(params) {
  return server.equipment.idQuery(params);
}

// 获取标识解析数
export function trackers(params) {
  return server.equipment.trackers(params);
}

// 获取地图标记点弹窗详情
export function getTrackerById(params) {
  return server.equipment.getTrackerById({params});
}

// 获取地图标记点弹窗详情图片
export function getRelateImgById(params) {
  return server.equipment.getRelateImgById({params});
}

// 获取产品解析量
export function countProduct(params) {
  return server.equipment.countProduct(params);
}

// 获取企业设备合同列表
export function getEquipmentList(params) {
  return server.equipment.getEquipmentList(params);
}

// 获取该用户所有的企业设备合同列表
export function getContractAndTrackerByUserId(params) {
  return server.equipment.getContractAndTrackerByUserId(params);
}

// 从后管点击预览获取该用户所有的企业设备合同列表
export function getContractAndTrackerByUserIdSkip(params) {
  return server.equipment.getContractAndTrackerByUserIdSkip(params);
}

// 查看企业设备合同详情
export function checkEquipment(params) {
  return server.equipment.checkEquipment({
    params
  });
}

// 查看设备在线数量
export function messageAll(params) {
  return server.equipment.messageAll({
    params
  });
}

// 获取用户下所有的设备合同里的设备
export function getAllTrackerVoByUserIdWithNoPageNoQuery(params) {
  return server.equipment.getAllTrackerVoByUserIdWithNoPageNoQuery({
    params
  });
}

// 从后管点击预览根据id获取用户下所有的设备合同里的设备
export function getAllTrackerVoByUserIdWithNoPageNoQueryId(params) {
  return server.equipment.getAllTrackerVoByUserIdWithNoPageNoQueryId({
    params
  });
}

// 从后管点击预览根据id获取用户下所有的设备合同里的设备
export function selectContractById(params) {
  return server.equipment.selectContractById({params});
}

// 可关联设备
export function associateEquipment(params) {
  return server.equipment.associateEquipment(params);
}

// 已关联设备（分页）
export function getAllTrackerByUserId(params) {
  return server.equipment.getAllTrackerByUserId(params);
}

// 铝模板溯源列表（分页）
export function getTraceabilityByUserId(params) {
  return server.equipment.getTraceabilityByUserId({params});
}

// 铝模板溯源楼栋下拉选项
export function getQueryBuildingInfoByContractOn(params) {
  return server.equipment.getQueryBuildingInfoByContractOn({params});
}

// 铝模板溯源详情页
export function getTraceabilityDetail(params) {
  return server.equipment.getTraceabilityDetail({params});
}

// 已关联设备（全部）
export function getTrackListByContractId(params) {
  return server.equipment.getTrackListByContractId(params);
}

// 关联设备
export function batchInsertContractLink(params) {
  return server.equipment.batchInsertContractLink(params);
}

// 删除联设备合同
export function delById(params) {
  return server.equipment.delById({
    params
  });
}

// 获取项目列表
export function cloudList(params) {
  return server.equipment.cloudList(params);
}

// 获取移动中、在线 、离线的设备数量
export function countTrackerStatusByUserId(params) {
  return server.equipment.countTrackerStatusByUserId(params);
}

// 从后管预览跳转到设备根据id获取移动中、在线 、离线的设备数量
export function countTrackerStatusByUserIdSkip(params) {
  return server.equipment.countTrackerStatusByUserIdSkip({params});
}

// 地图 -设备合同列表
export function getContractByUseId(params) {
  return server.equipment.getContractByUseId(params);
}

// 获取对应省下所有的市
export function getCity(params) {
  return server.equipment.getCity({params});
}

// 获取对应市下所有的县/区
export function getCounty(params) {
  return server.equipment.getCounty({params});
}


// 物资交付
export function contractInfo(params) {
  return server.other.contractInfo({params});
}

// 朔源查询
export function contractEntityQuery(params) {
  return server.other.contractEntityQuery({params});
}


// 产品信息注册量
export function dataScreenSummary(params) {
  return server.other.dataScreenSummary({params});
}