<template>

    <div class="dialog-wrap" v-show="status">
        <div class="tableBox">
            <div class="box-title">
                项目列表<i class="el-icon-close pointer" @click="closeDialog"></i>
            </div>
            <div class="table">
                <div class="table-queryConditions">
                    <span>项目名称：</span>
                    <el-input v-model="projName" size='small' placeholder="请输入内容" class="query-input">
                    </el-input>
                    <el-button size='small' class="query-botton" @click="queryData">查询</el-button>
                </div>

                <el-table ref="multipleTable" :data="contractList" tooltip-effect="dark" @select='selects'
                    @select-all="selectAll" v-loading="contractLoading"
                    :header-cell-style='{color:"#00ebff","font-size":"16px!important"}'
                    @cell-mouse-enter='cellmouseenter' @cell-mouse-leave='cellmouseleave'
                    style="width: 100%;color:#fff;height:540px" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" label="全选"></el-table-column>
                    <el-table-column type="index" width="60" label="序号"></el-table-column>
                    <el-table-column prop="projName" label="项目名称" width="240" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="contractNo" label="合同编号" width="240" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="developer" label="开发商" width="240" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="constractor" label="承建商" width="240" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sellMode" label="销售模式" width="100"></el-table-column>
                    <el-table-column prop="projType" label="项目类别" width="100"></el-table-column>
                    <el-table-column label="业务负责人" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button v-if="buttonshow === scope.row.index" size='mini'
                                @click='getDetails(scope.row.id)'>
                                项目详情</el-button>
                            <span v-else>{{ scope.row.projLeader }}</span>
                        </template>

                    </el-table-column>
                </el-table>

                <div class="table-pagination">
                    <el-pagination background layout="prev, pager, next" :total='contract.total' :page-size='10'
                        :disabled='contractLoading' :current-page.sync="contract.currentPage"
                        @current-change="changeCurrentPage('contract')">
                    </el-pagination>
                </div>
            </div>
        </div>

        <div class="Details" v-if="isDetails">
            <div class="addOrRevise">
                <div class="box-title">
                    项目详情<i class="el-icon-close pointer" @click="isDetails = false"></i>
                </div>
                <div class="Details-body" v-loading="details_loading">
                    <div class="subtitle">
                        <span>项目信息</span>
                        <div class="subtitle-string"></div>
                    </div>
                    <el-form ref='form' :inline="true" :model="formData" label-position="right" label-width="130px">
                        <el-form-item label="项目名称" prop="projName" style="width:48%">
                            <div class="formBox overflow">{{formData.projName}}</div>
                        </el-form-item>
                        <el-form-item label="项目所在地" prop="projAddress" style="width:48%">
                            <div class="formBox overflow">{{formData.projAddress}}</div>
                        </el-form-item>
                        <el-form-item label="开发商" prop="developer" style="width:48%">
                            <div class="formBox overflow">{{formData.developer}}</div>
                        </el-form-item>
                        <el-form-item label="承建商" prop="constractor" style="width:48%">
                            <div class="formBox overflow">{{formData.constractor}}</div>
                        </el-form-item>
                        <el-form-item label="合同签约单位" prop="contractEntp" style="width:48%">
                            <div class="formBox overflow">{{formData.contractEntp}}</div>
                        </el-form-item>
                        <el-form-item label="合同编号" prop="contractNo" style="width:48%">
                            <div class="formBox overflow">{{formData.contractNo}}</div>
                        </el-form-item>
                        <el-form-item label="销售模式" prop="sellMode" style="width:48%;">
                            <div class="formBox overflow">{{formData.sellMode}}</div>
                        </el-form-item>
                        <el-form-item label="铝膜栋数" prop="lvDs" style="width:48%">
                            <div class="formBox overflow">{{formData.lvDs}}</div>
                        </el-form-item>
                        <el-form-item label="单层面积" prop="areaEc" style="width:48%">
                            <div style="display:flex">
                                <div class="formBox overflow formBox-tag">{{formData.areaEc}}</div>
                                <div class="tags">平分米 / m²</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="层数" prop="lvCs" style="width:48%">
                            <div class="formBox overflow">{{formData.lvCs}}</div>
                        </el-form-item>
                        <el-form-item label="项目类别" prop="projType" style="width:48%">
                            <div class="formBox overflow">{{formData.projType}}</div>
                        </el-form-item>
                        <el-form-item label="业务负责人" prop="projLeader" style="width:48%">
                            <div class="formBox overflow">{{formData.projLeader}}</div>
                        </el-form-item>
                        <el-form-item label="铝模板综合单价" prop="lvPrices" style="width:48%">
                            <div style="display:flex">
                                <div class="formBox overflow formBox-tag">{{formData.lvPrices}}</div>
                                <div class="tags">元/方</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="合同金额" prop="contractAmt" style="width:48%">
                            <div class="formBox overflow">{{formData.contractAmt}}</div>
                        </el-form-item>
                        <el-form-item label="租期" prop="rentTerm" style="width:48%">
                            <div style="display:flex">
                                <div class="formBox overflow formBox-tag">{{formData.rentTerm}}</div>
                                <div class="tags">月</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="合同进场时间" prop="addTime" style="width:48%">
                            <div class="formBox overflow">{{formData.addTime}}</div>
                        </el-form-item>
                        <el-form-item label="合同文件" style="width:48%">
                            <div v-for="item in fileList" :key="item.url" style="color:#fff;font-size:16px">
                                <span class="pointer" @click="download(item.url)">{{item.name}}</span>
                            </div>
                        </el-form-item>
                    </el-form>

                    <div class="subtitle">
                        <span>设备信息</span>
                        <div class="subtitle-string"></div>
                    </div>

                    <el-table :data="myDevicee_listData" tooltip-effect="dark"
                        :header-cell-style='{color:"#00ebff","font-size":"16px!important"}'
                        style="width: 100%;color:#fff;padding:0 10px">
                        <el-table-column prop="dealer" label="经销商" show-overflow-tooltip width="200">
                        </el-table-column>
                        <el-table-column prop="trackerName" label="设备名称" width="300" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="model" label="型号" width="120" show-overflow-tooltip></el-table-column>
                        <el-table-column align='center' prop="imei" label="设备卡号"></el-table-column>
                        <el-table-column prop="status" label="设备状态" width="120" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="formattedAddress" label="地址" show-overflow-tooltip></el-table-column>
                    </el-table>

                    <div class="table-pagination">
                        <el-pagination background layout="prev, pager, next" :total='myDevicee_pagination.total'
                            :page-size='10' :current-page.sync="myDevicee_pagination.currentPage"
                            @current-change="changeCurrentPage('myDevicee')">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getContractByUseId,
        checkEquipment,
        getAllTrackerByUserId,
        getContractAndTrackerByUserId,
      getContractAndTrackerByUserIdSkip
    } from '../api'
    export default {
         mounted() {
           this.urlmethod()
          if(this.userId){
            // 从后管点击预览调整根据id获取全部的设备合同并截取其开头10条作为首次展示
            getContractAndTrackerByUserIdSkip({
              userId:this.userId,
              projName: '',
              pageNum: 1,
              pageSize: 999,
            }).then(e => {
              if (e.errno == 0) {
                var change = {
                  SELL: '销售',
                  RENT: '出租',
                  ZZ: '住宅',
                  SY: '商用',
                  XZL: '写字楼',
                  ORTHER: '其他'
                }
                this.contract.total = e.data.total
                var data = []
                var list = e.data.list.slice(0, 10)
                list.forEach((e, index) => {
                  e.projType = change[e.projType]
                  e.sellMode = change[e.sellMode]
                  e.index = index
                  data.push(e)
                });
                this.contractList = [...data]
                // this.$refs.multipleTable.toggleAllSelection()


                e.data.list.map(e => {
                  this.allContract[e.id] = e.trackerVoList
                })

                this.$nextTick(e => {
                  this.contractList.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                  });
                })
              }
              this.contractLoading = false
            })
          } else {
            // 获取全部的设备合同并截取其开头10条作为首次展示
            getContractAndTrackerByUserId({
              projName: '',
              pageNum: 1,
              pageSize: 999,
            }).then(e => {
              if (e.errno == 0) {
                var change = {
                  SELL: '销售',
                  RENT: '出租',
                  ZZ: '住宅',
                  SY: '商用',
                  XZL: '写字楼',
                  ORTHER: '其他'
                }
                this.contract.total = e.data.total
                var data = []
                var list = e.data.list.slice(0, 10)
                list.forEach((e, index) => {
                  e.projType = change[e.projType]
                  e.sellMode = change[e.sellMode]
                  e.index = index
                  data.push(e)
                });
                this.contractList = [...data]
                // this.$refs.multipleTable.toggleAllSelection()


                e.data.list.map(e => {
                  this.allContract[e.id] = e.trackerVoList
                })

                this.$nextTick(e => {
                  this.contractList.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                  });
                })
              }
              this.contractLoading = false
            })

          }
            // this.getContractByUseId()
        },
        data() {
            return {
                userId: '',
                contractLoading: true,
                details_loading: true,
                allContract: {}, // 所有选择的合同id
                delContract: {}, // 去掉显示的合同id
                addContract: {}, // 增加显示的合同id
                contractId: '',
                projTotal: 0,
                isDetails: false,
                buttonshow: '',
                query: '',
                projName: '',
                contractList: [],
                formData: {
                    projName: '',
                    projAddress: '',
                    developer: '',
                    constractor: '',
                    contractEntp: '',
                    contractNo: '',
                    sellMode: '',
                    lvDs: '',
                    areaEc: '',
                    lvCs: '',
                    projType: '',
                    projLeader: '',
                    lvPrices: '',
                    contractAmt: '',
                    rentTerm: '',
                    addTime: '',
                },
                fileList: [],
                myDevicee: {
                    total: 0
                },
                myDevicee_listData: [],
                myDevicee_pagination: {
                    total: 0,
                    currentPage: 0,
                },
                contract: {
                    total: 0,
                    currentPage: 0,
                }
            }
        },
        props: {
            status: {
                type: Boolean
            },
            clientInfo_id: {
                type: [String, Number]
            },
            allDevice: {
                type: Object
            }
        },
        methods: {
          // 从后管预览跳转到设备合同获取传递过来的id
          urlmethod(){
              let urlParam = location.href
              if (urlParam.indexOf('id')!==-1){
                let param =urlParam.split('=')
                this.userId = Number(param[1].replace('ab', '').replace('gf',''))/100;
              }
            },
            // 移入表格某一列
            cellmouseenter(row, column, cell, event) {
                this.buttonshow = row.index
            },

            // 移出表格某一列
            cellmouseleave(row, column, cell, event) {
                this.buttonshow = false
            },

            //
            handleSelectionChange() {},

            // 获取项目详情
            async getDetails(e) {
                this.details_loading = true
                this.isDetails = true,
                    this.myDevicee_pagination.currentPage = 1
                this.contractId = e,
                    await this.checkEquipment()
                await this.getAllTrackerByUserId()
                this.details_loading = false
            },

            // 下载
            download(e) {
                window.open(e)
            },

            // 获取合同详情
            checkEquipment() {
                var _this = this

                return checkEquipment({
                    id: this.contractId
                }).then(e => {
                    if (e.errno == 0) {
                        var change = {
                            'SELL': '销售',
                            'RENT': '出租',
                            'ZZ': '住宅',
                            'SY': '商用',
                            'XZL': '写字楼',
                            'ORTHER': '其他'
                        }
                        var contractFile
                        var contractFileName
                        var fileList = []
                        var formData = {}
                        Object.keys(_this.formData).forEach(key => {
                            formData[key] = e.data[key]
                        })
                        _this.formData = formData
                        _this.formData.sellMode = change[_this.formData.sellMode]
                        _this.formData.projType = change[_this.formData.projType]
                        contractFile = e.data.contractFile.split(",")
                        contractFileName = e.data.contractFileName.split(",")
                        contractFile.forEach((e, index) => {
                            fileList.push({
                                name: contractFileName[index],
                                url: e
                            })
                        })
                        _this.fileList = fileList
                    } else {
                        this.$message({
                            message: e.errmsg,
                            type: 'error'
                        })
                    }
                })
            },

            // 关闭窗口
            closeDialog() {
                var deviceId = []
                console.log(this.allContract)

                Object.keys(this.allContract).forEach(e => {
                    console.log(this.allContract[e], e)
                    // debugger
                    this.allContract[e].forEach(row => {
                        deviceId.push(row.imei)
                    })
                })
                // debugger
                this.$emit('getdeviceId', deviceId)

                this.$emit('getstatus', false)
            },

            // 获取已关联设备信息
            getAllTrackerByUserId(pageNum = 1, pageSize = 10) {
                var contractId = this.contractId

                return getAllTrackerByUserId({
                    contractId,
                    pageNum,
                    pageSize
                }).then(e => {
                    if (e.errno == 0) {
                        var change = {
                            NOTACTIVE: '未激活',
                            NORMAL: '已激活',
                            INVALID: '已到期'
                        }
                        this.myDevicee_listData = e.data.list.map(value => {
                            value.status = change[value.validStatus]
                            value.chooseFlag = true
                            return value
                        })

                        this.myDevicee_pagination.total = e.data.total
                        return
                    }
                    this.$message({
                        message: e.errmsg,
                        type: 'error'
                    })

                })
            },

            // 获取设备合同列表
            getContractByUseId(pageNum = 1, pageSize = 10) {
                var params = {
                    projName: this.query,
                    pageNum: pageNum,
                    pageSize: pageSize,
                }
                var _this = this
                this.contractLoading = true
              if(this.userId){
                params.userId = this.userId;
                getContractAndTrackerByUserIdSkip(params).then(e => {
                  if (e.errno == 0) {
                    var change = {
                      SELL: '销售',
                      RENT: '出租',
                      ZZ: '住宅',
                      SY: '商用',
                      XZL: '写字楼',
                      ORTHER: '其他'
                    }
                    this.contract.total = e.data.total

                    var data = []

                    e.data.list.forEach((e, index) => {
                      e.projType = change[e.projType]
                      e.sellMode = change[e.sellMode]
                      e.index = index
                      data.push(e)
                    });

                    this.contractList = [...data]

                    this.$nextTick(e => {
                      _this.contractList.forEach((row) => {
                        if (_this.allContract[row.id]) {
                          _this.$refs.multipleTable.toggleRowSelection(row);
                        }
                      });
                    })
                  }
                  this.contractLoading = false
                })
                return
              }
                getContractAndTrackerByUserId(params).then(e => {
                    if (e.errno == 0) {
                        var change = {
                            SELL: '销售',
                            RENT: '出租',
                            ZZ: '住宅',
                            SY: '商用',
                            XZL: '写字楼',
                            ORTHER: '其他'
                        }
                        this.contract.total = e.data.total

                        var data = []

                        e.data.list.forEach((e, index) => {
                            e.projType = change[e.projType]
                            e.sellMode = change[e.sellMode]
                            e.index = index
                            data.push(e)
                        });

                        this.contractList = [...data]

                        this.$nextTick(e => {
                            _this.contractList.forEach((row) => {
                                if (_this.allContract[row.id]) {
                                    _this.$refs.multipleTable.toggleRowSelection(row);
                                }
                            });
                        })
                    }
                    this.contractLoading = false
                })
            },

            // 查询
            queryData() {
                this.query = this.projName
                this.contract.currentPage = 1
                this.getContractByUseId()
            },

            //分页跳转
            changeCurrentPage(type) {
                if (type == 'contract') {
                    this.getContractByUseId(this.contract.currentPage)
                } else {
                    this.getAllTrackerByUserId(this.myDevicee_pagination.currentPage)
                }
            },

            // 勾选操作
            selects(selection, row) {
                var allContract = this.allContract
                var deleted = false
                var all = {}

                Object.keys(allContract).forEach(e => {
                    if (row.id == e) {
                        deleted = true
                        return
                    }
                    all[e] = allContract[e]
                })

                if (deleted == false) {
                    all[row.id] = row.trackerVoList
                }

                this.allContract = all
                // debugger
            },

            selectAll(val) {
                // debugger
                var all = {},
                    allContract = {
                        ...this.allContract
                    };
                if (!val.length) {
                    // 取消全部
                    this.contractList.forEach(row => {
                        if (allContract[row.id]) {
                            delete allContract[row.id]
                        }
                    });
                } else {
                    // 选择全部
                    val.forEach(row => {
                        if (!allContract[row.id]) {
                            allContract[row.id] = row.trackerVoList
                        }
                    });
                }

                this.allContract = allContract
            }
        },
    }
</script>

<style lang='less' scoped>
    @titleBg: #0040b2;
    @usuallyBg: #00ebff;
    @inputBg: #01428b;
    @fontColor: #00297a;

    .dialog-wrap {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        padding-top: 100px;
        background: rgba(1, 3, 9, 0.2);
        font-size: 16px;

        .tableBox {
            background: #05143B;
            box-shadow: 0px 0px 20px rgba(0, 85, 255, 0.5) inset;
            box-sizing: border-box;
            border: 1px solid #0158D6;
            color: #fff;
            position: relative;
            z-index: 98;
            width: 1442px;
            height: 748px;
            margin: 0 auto;

            .table {
                padding: 20px;

                .table-queryConditions {
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid @usuallyBg;
                    padding: 3px 0 17px 18px;
                    margin-bottom: 20px;

                    .query-botton {
                        background: @usuallyBg;
                        border-color: @usuallyBg;
                        color: @fontColor;
                        font-size: 16px;
                        padding: 6px 12px;
                    }

                    span {
                        color: @usuallyBg;
                        font-size: 16px;
                    }

                    .query-input {
                        width: 200px;
                        margin-right: 13px;

                        & /deep/.el-input__inner {
                            background: @inputBg;
                            border-color: @inputBg;
                            color: #FFF;
                        }
                    }
                }
            }
        }

        .Details {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100%;
            height: 100%;
            padding-top: 100px;
            background: rgba(0, 0, 0, 0.7);

            .addOrRevise {
                background: #001138;
                z-index: 99;
                width: 1235px;
                margin: 0 auto;
                position: relative;
                box-sizing: border-box;
                border: 2px solid #0247ed;
                border-top: none;
                box-shadow: 0px 0px 20px rgba(0, 85, 255, 0.8) inset;

                .Details-body {
                    height: 700px;
                    overflow-y: auto;
                    padding: 20px;

                    &::-webkit-scrollbar {
                        /*高宽分别对应横竖滚动条的尺寸*/
                        /*滚动条整体样式*/
                        width: 6px;
                        // height: 1px;
                    }

                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面小方块*/
                        border-radius: 12px;
                        background: @usuallyBg;
                    }

                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道*/
                        border-radius: 12px;
                        background: transparent;
                    }

                    .subtitle {
                        display: flex;
                        margin-bottom: 20px;
                        color: @usuallyBg;
                        font-size: 16px;

                        .subtitle-string {
                            flex: 1;
                            position: relative;

                            &::after {
                                position: absolute;
                                left: 12px;
                                bottom: 4px;
                                content: '';
                                width: 100%;
                                height: 1px;
                                background: @usuallyBg;
                            }
                        }
                    }
                }
            }
        }
    }

    // 项目详情的数据展示框
    .formBox {
        width: 200px;
        height: 38px;
        background: #FFF;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        background: @inputBg;
        color: #fff;
        width: 360px;
        box-sizing: border-box;
        font-size: 16px;

        &.formBox-tag {
            width: 240px;
        }
    }

    // 项目详情的数据展示框
    .tags {
        display: inline-block;
        width: 100px;
        margin-left: 20px;
        padding-left: 10px;
        box-sizing: border-box;
        height: 40px;
        color: #fff;
        background: @inputBg;
        border-radius: 4px;
        font-size: 16px;
        
    }

    // 分页器
    .table-pagination {
        text-align: right;
        margin-top: 18px;
    }

    // 分页器
    /deep/.el-pagination {
        .btn-prev {
            background: @inputBg  !important;
            color: @usuallyBg  !important;
        }

        ul {
            .number {
                background: @inputBg ;
                color: @usuallyBg;

                &.active {
                    background: @usuallyBg  !important;
                    color: @inputBg  !important;
                }
            }

            .el-icon-more {
                background: @inputBg  !important;
                color: @usuallyBg  !important;
            }
        }

        .btn-next {
            background: @inputBg  !important;
            color: @usuallyBg  !important;
        }
    }

    // 项目列表 
    .box-title {
        background: @titleBg;
        width: 100%;
        line-height: 1;
        padding: 15px 14px 0 21px;
        box-sizing: border-box;
        height: 48px;
        color: #fff;
        font-size: 16px;

        &::before {
            position: absolute;
            left: 0px;
            top: 0px;
            content: '';
            width: 0;
            height: 0;
            border: 6px solid;
            border-color: #00ebff transparent transparent #00ebff;
        }

        .el-icon-close {
            font-size: 20px;
            font-weight: bold;
            float: right;
            color: @usuallyBg;
        }
    }

    // 表格
    /deep/.el-form-item__label {
        color: @usuallyBg;
    }


    /deep/.el-form-item {
        margin-bottom: 30px;
    }

    /deep/ .el-table,
    /deep/ .el-table__expanded-cell {
        background-color: transparent;
        border-color: transparent;
    }

    /deep/ .el-table th,
    /deep/ .el-table tr {
        background-color: transparent;
        border-color: transparent;
    }

    // 处理表格背景色透明
    /deep/.el-table tr {
        border-color: transparent;
    }

    /deep/.el-table td,
    .el-table th.is-leaf {
        border-color: transparent;
    }

    /deep/.el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
        background-color: transparent;
    }

    // 修改表格列悬停时颜色
    /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: #042260 !important;
        color: #fff !important;
    }

    /deep/.cell {
        font-size: 16px !important;
    }

    // 加载
    /deep/.el-loading-mask {
        background: transparent;
    }

    /deep/.circular {
        width: 80px;
        height: 80px
    }

    //     // 取消表格标题处的全选框

    // /deep/.el-table__header-wrapper {
    //     .el-checkbox__inner {
    //         display: none;
    //     }
    // }
</style>