<template>
  <div>
    <img class="arc" src="@/assets/map/arc.png" />
    <div class="condition">
      <p class="title">项目情况</p>
      <p class="all-number">
        <span>
          <span style="color: #92edff">项目总数</span>
          <span style="color: #fff">(45534)</span>
        </span>
        <span class="more" @click="$refs.dialogList.dialogVisible = true">查看更多合同</span>
      </p>
      <div class="table-content">
        <div style="width: 49%">
          <el-table class="table" :data="tableData" border style="width: 100%">
            <el-table-column prop="date" label="项目名称" align="center" header-align="center" show-overflow-tooltip />
            <el-table-column prop="name" label="固投(亿元)" align="center" header-align="center" width="88" show-overflow-tooltip />
            <el-table-column prop="address" label="项目类别" align="center" header-align="center" width="80" show-overflow-tooltip />
            <el-table-column prop="address" label="操作" align="center" header-align="center" width="108" show-overflow-tooltip>
              <template>
                <div style="cursor: pointer; width: 100%; height: 23px; border-radius: 50px; border: 1px solid #fff; color: #fff" @click="$refs.dialogDetail.dialogVisible = true">查看详情</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 49%">
          <el-table :data="tableData2" :highlight-current-row="false" border style="width: 100%">
            <el-table-column prop="date" label="项目名称" align="center" header-align="center" show-overflow-tooltip />
            <el-table-column prop="name" label="固投(亿元)" align="center" header-align="center" width="88" show-overflow-tooltip />
            <el-table-column prop="address" label="项目类别" align="center" header-align="center" width="80" show-overflow-tooltip />
            <el-table-column prop="address" label="操作" align="center" header-align="center" width="108" show-overflow-tooltip>
              <template>
                <div style="cursor: pointer; width: 100%; height: 23px; border-radius: 50px; border: 1px solid #fff; color: #fff" @click="$refs.dialogDetail.dialogVisible = true">查看详情</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 合同详情弹框组件 -->
    <dialog-detail ref="dialogDetail" @toEquipment="toEquipment" />
    <!-- 设备详情弹框组件 -->
    <dialog-equipment ref="dialogEquipment" @backDetail="backDetail" />
    <!-- 设备列表弹框组件 -->
    <dialog-list ref="dialogList" @toEquipment="toDetail"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          date: '固态铝合金',
          name: '2.68亿元',
          address: '商用'
        },
        {
          date: 'HUYD钣金',
          name: '1.4亿元',
          address: '个人'
        },
        {
          date: '长版铝合金',
          name: '1.8亿元',
          address: '商用'
        }
      ],
      tableData2: [
        {
          date: '钛合金',
          name: '1.68亿元',
          address: '商用'
        },
        {
          date: '铝板材',
          name: '1.1亿元',
          address: '个人'
        },
        {
          date: '钢板材',
          name: '3.8亿元',
          address: '个人'
        }
      ]
    };
  },
  components: {
    dialogDetail: () => import('@/modules/DeviceMap/components/dialog-detail.vue'),
    dialogList: () => import('@/modules/DeviceMap/components/dialog-list.vue'),
    dialogEquipment: () => import('@/modules/DeviceMap/components/dialog-equipment.vue')
  },
  methods: {
    toEquipment() {
      this.$refs.dialogDetail.dialogVisible = false;
      this.$refs.dialogEquipment.dialogVisible = true;
    },
    backDetail() {
      this.$refs.dialogEquipment.dialogVisible = false;
      this.$refs.dialogDetail.dialogVisible = true;
    },
    toDetail() {
      this.$refs.dialogList.dialogVisible = false;
      this.$refs.dialogDetail.dialogVisible = true;
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.arc {
  position: absolute;
  bottom: 0;
  left: calc(50% - 885px);
}
.condition {
  padding: 20px 90px 0 90px;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 499px);
  width: 997px;
  height: 220px;
  background: url('../../../assets/map/condition.png') no-repeat center;
  background-size: 100% 100%;
}
.title {
  padding-left: 50px;
  font-size: 20px;
  color: #fff;
}
.all-number {
  position: relative;
  text-align: center;
  font-size: 16px;
}
.more {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
  text-decoration: underline;
  color: #83e1f5;
}
.table-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  & ::v-deep th {
    background-color: #03437b;
    padding: 3px 0;
    color: #fff;
  }
  & ::v-deep td {
    background-color: #042a4a;
    padding: 3px 0;
    color: #04c5e0;
  }
  & ::v-deep .el-table--border .el-table__cell {
    border-color: #0268b0;
  }
  & ::v-deep .el-table--border {
    border-color: #0268b0;
  }
  .el-table--border::after,
  .el-table--group::after {
    background-color: #0268b0;
  }
  .el-table--border::before,
  .el-table--group::before {
    background-color: #0268b0;
  }
  & ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #042a4a;
  }
}
</style>
